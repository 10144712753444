import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Slider from 'react-slick'
import theme from '../styles/theme'
import Img from 'gatsby-image'
import Button from './Button'

require('slick-carousel/slick/slick.css')
require('slick-carousel/slick/slick-theme.css')

const WrapperPost = styled.section`

`
const Post = styled.div`
  transition: background 0.2s;
  position: relative;
  width: 100%;
  margin: 0 auto 2vw auto;
  padding: 0 10px;

  .post-content {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    color: ${props => props.theme.colors.base};
    text-decoration: none;
    justify-content: center;
    /* .gatsby-image-wrapper {
      height: 0;
      padding-bottom: 100%;
      @media screen and (min-width: ${props => props.theme.responsive.small}) {
        padding-bottom: ${props => (props.featured ? '100%' : '100%')};
      }
    } */
  }

  .post-properties {
    margin-top: 1.5em;
    display: flex;
    flex-direction: column;
  }

  .button-content {
    // width: 60%;
    margin: 2em 2em 2em 0 ;
    & a {
      text-decoration: none;
    }
  }
  
  @media screen and (max-width: ${props => props.theme.breakpoint.medium}px) {
    padding: 0 0.5em;
    .button-content {
      // width: 80%;
    }
  }
  @media screen and (max-width: ${props => props.theme.breakpoint.smallMedium}px) {
    padding: 0 0.6em;
  }
`

const PreviewImage = styled(Img)`
  min-height: 240px;
  max-height: 347px;
  margin-top: 0.5em;
`

const PreviewImageContent = styled.div`
  position: relative;

  .brand-logo-content {
    width: 100%;
    padding: 2% 8%;
    background: ${props => props.theme.colors.greenOverlay};
    position: absolute;
    z-index: 3;
    margin: auto 0;

    top: 0;
    bottom: 0;
    height: 20%;
  }
`

const Wrapper = styled.section`
  padding-top: 4.3em;
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;
  margin-bottom: ${props => props.marginBottom};
`

const Wrapper2 = styled.section`
  margin: 0 auto;
  // max-width: ${props => props.maxWidth}px;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  padding: 0 15px;
  margin-top: 0.5em;

  & h3.blog-title {
    font-family: Raleway;
    font-size: 28px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 33px;
    letter-spacing: 0.239186px;
    color: ${props => props.theme.colors.black};
    text-align: left;
    margin-top: 0.1em;
  }

  & p.blog-excerpt {
    font-family: Raleway;
    font-size: 22px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 26px;
    letter-spacing: normal;
    text-align: left;
    margin-top: 0.7em;
    color: ${props => props.theme.colors.black};

    em {
      font-style: italic;
    }
  }

  & .slick-list {
    padding: 0;
  }

  @media screen and (max-width: ${props => props.theme.breakpoint.extraLarge}px) {
    // padding: 0 4em;
  }
  @media screen and (max-width: ${props => props.theme.breakpoint.medium}px) {
    // padding: 0 3em;
    & h3.blog-title { 
      font-size: 20px;
      line-height: 20px;
    }
    & p.blog-excerpt { 
      font-size: 16px;
      line-height: normal;
    }
  }
  @media screen and (max-width: ${props => props.theme.breakpoint.smallMedium}px) {
    padding: 0 0;
    margin-top: 4em;

    .slick-list {
      padding: 0px 40px !important;
    }

    & h3.blog-title { 
      font-size: 28px;
      line-height: 33px;
    }
    & p.blog-excerpt { 
      font-size: 20px;
      line-height: normal;
    }
  }
  @media screen and (max-width: ${props => props.theme.breakpoint.small}px) {
    padding: 0 0;
    & h3.blog-title { 
      font-size: 18px;
      line-height: 24px;
    }
    & p.blog-excerpt { 
      font-size: 12px;
      line-height: normal;
    }
  }
`

const Title = styled.h1`
  width: 100%;
  padding: 1.3em 25px 0;
  font-family: Raleway;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  font-size: 36px;
  text-align: left;
  letter-spacing: 0.177083px;
  color: ${props => props.theme.colors.darkGrey};

  max-width: ${props => props.theme.sizes.maxWidthCentered};
  margin: auto;

  @media screen and (max-width: ${props => props.theme.breakpoint.small}px) {
    line-height: 25px;
    font-size: 18px;
    letter-spacing: 0.43px;
    padding-left: 1em;
    padding-right: 1em;

    &.subtitle {
      font-size: 24px; 
      line-height: 125%;
    }
  }
`

const FeacturedPosts = props => {
  const slidesRowlength = props.posts ? props.posts.length : 2
  // const maxWidthSlider = (slidesRowlength * 347) + ((slidesRowlength - 1) * 30 )

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    rows: 2,
    slidesPerRow: slidesRowlength,
    arrows: false,
    dotsClass: 'slick-dots-new',
    // centerMode: true,
    responsive: [
      {
        breakpoint: theme.breakpoint.smallMedium,
        settings: {
          slidesPerRow: 1,
          rows: 1,
          centerMode: true,
          centerPadding: '40px',
        },
      },
    ],
  }

  return (
    <Wrapper marginBottom={props.marginBottom ? props.marginBottom : '0px'}>
      <Title className="subtitle">{props.title || ''}</Title>
      <Wrapper2>
        <Slider {...settings}>
          {props.posts.map(({ node: inf }, index) => (
            <WrapperPost key={`homePost-${inf.id}`}>
              <Post>
                <div className="post-content">
                  <Link to={`/blog/${inf.slug}`}>
                    <PreviewImageContent>
                      <PreviewImage fluid={inf.heroImage.fluid} />
                    </PreviewImageContent>
                  </Link>
                  <div className="post-properties">
                    <h3 className="blog-title">{inf.title}</h3>
                    <p className="blog-excerpt">{inf.excerpt}</p>
                  </div>
                  <div className="button-content">
                    <Link to={`/blog/${inf.slug}`}>
                      <Button padding="9px 25px">READ MORE</Button>
                    </Link>
                  </div>
                </div>
              </Post>
            </WrapperPost>
          ))}
        </Slider>
      </Wrapper2>
    </Wrapper>
  )
}

export default FeacturedPosts
