import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import styled from 'styled-components'
import Hero from '../components/Hero'
import SEO from '../components/SEO'
import TheTeam from '../components/TheTeam'
import TheTeamCard from '../components/TheTeamCard'
import Img from 'gatsby-image'
import Title from '../components/Title'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import FeacturedPosts from '../components/FeacturedPosts';
import ListPressPosts from '../components/ListPressPosts';
import Button from '../components/Button';
import GetQuoteSection from '../components/GetQuoteSection'

const Wrapper = styled.section`
  text-align: center;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  margin: auto;

  .button-content {
    & a {
      text-decoration: none;
    }
  }
`

const SeparatorLine = styled.div`
  width: 155px;
  height: 2px;
  border: solid 1.5px ${props => props.theme.colors.secondary};
  margin: auto;
`

const SeparatorTopLine = styled(SeparatorLine)`
  margin-top: 3em;
`

const CompanyTitle = styled.div`
  padding: 0 25px;
  margin: 4.3em 0 1em 0;
  object-fit: contain;
  font-family: Raleway;
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 33px;
  text-align: left;
  letter-spacing: 0.239186px;
  color: ${props => props.theme.colors.tertiary};
`

const PressPageTemplate = ({ data, pageContext }) => {
  const content = data.contentfulPressPage
  const posts = data.allContentfulPress.edges || []

  const nextPage = pageContext.currentPage < pageContext.numPages ? pageContext.currentPage + 1 : false;

  let layoutRef = React.createRef();
  
  const handleOnClickSignUp = () => {
    if (layoutRef && 
      layoutRef.current &&
      layoutRef.current.menuContainerRef) {
      
      const menuContainerRef = layoutRef.current.menuContainerRef;
      if (menuContainerRef.current && 
        menuContainerRef.current.menuRef && 
        menuContainerRef.current.menuRef.current &&
        menuContainerRef.current.menuRef.current.openInfluencerRegisterModal
      ) {
        menuContainerRef.current.menuRef.current.openInfluencerRegisterModal();
      }
    }
  }

  return (
    <Layout ref={layoutRef}>
      <Helmet>
        {/* <link rel="stylesheet" 
          href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" 
          integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" 
          crossOrigin="anonymous" 
        /> */}
        <link 
          rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/css/bootstrap-grid.min.css" 
          integrity="sha256-D9AvR0EzkNZoWZVSvI3ch+uf/Z5izrIpcbsykVphwPU=" 
          crossOrigin="anonymous" 
        />
        <title>{`${config.siteTitle}`}</title>
      </Helmet>
      <Hero
        image={content.heroImage}
        responsiveImage={content.heroImageResponsive}
        position={'top center'}
        isBlue
        footer={''}
        fixed
        responsive
      />

      <Wrapper>
        <Title paddingTop="1.5em" title={content.title} big/>

        <ListPressPosts
          title={"EMBELLO IN THE NEWS"}
          posts={posts}
        />

        <div className="d-flex justify-content-center my-5 button-content">
          {nextPage &&
            <Link to={`/press/${nextPage}`}>
              <Button 
                minWidth="180px"
                padding="9px 12px" 
                borderRadius="6.28832px"
                className="tertiary medium rounded mb-5">
                LOAD MORE
              </Button>
            </Link>
          }
        </div>
      </Wrapper>
      <GetQuoteSection 
        consultationTitle={'SIGN UP TO THE ONLY HOME FOCUSED INFLUENCER PLATFORM'}
        noMargin
        onClick={() => handleOnClickSignUp()}
        linkTitle={'SIGN UP'}
        paddingButton="9px 44px"
      />
    </Layout>
  )
}

export const query = graphql`
  query pressPageQuery($limit: Int!) {
    contentfulPressPage(version: { eq: "current" }) {
      version
      slug
      title
      heroImage {
        title
        fixed(width: 1800) {
          ...GatsbyContentfulFixed_withWebp_noBase64
        }
      }
      heroImageResponsive {
        title
        fixed(width: 1800) {
          ...GatsbyContentfulFixed_withWebp_noBase64
        }
      }
    }
    allContentfulPress(
      sort: { order: DESC, fields: [createdAt] } 
      limit: $limit
    ) {
      edges {
        node {
          id
          slug
          title
          createdAt
          heroImage {
            title
            fluid(maxWidth: 800, maxHeight: 800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          body {
            childMarkdownRemark {
              html
              excerpt
            }
          }
          metaDescription {
            internal {
              content
            }
          }
          excerpt
        }
      }
    }
  }
`

export default PressPageTemplate
